import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from 'react-autocomplete'
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import axios from 'axios'
import { toast } from "react-toastify";
import {
  validateEmail,
  validateNumber,
  validatePresent,
  validateState,
  isStateRequired
} from "../utils/validators";
import countries from "../data/countries";
import states from "../data/states";
import FileUpload from "./FileUpload";
import { findCandidate, saveCandidate } from "../actions/candidates";
import { findJob } from "../actions/jobs";

const autoCompleteMenuStyle = {
  position: 'fixed',
  zIndex: 1000,
  border: 'solid 1px #777777',
  padding: '5px 10px',
  background: 'white',
  overflow: 'auto'
}

class CandidateDetailsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,

    candidate: PropTypes.object.isRequired,

    findJob: PropTypes.func.isRequired,
    findCandidate: PropTypes.func.isRequired,
    saveCandidate: PropTypes.func.isRequired,
    formCandidateError: PropTypes.string.isRequired,
  };

  state = {
    cities: [],
    validationErrors: {},
    country: '',
    province: '',
    city: ''
  };

  _candidateDetailsForm = {
    title: "Submit candidate",
    sections: [
      {
        title: "Candidate Details",
        rows: [
          [
            {
              label: "First Name",
              name: "firstName",
              type: "text",
              validator: validatePresent,
            },
            {
              label: "Last Name",
              name: "lastName",
              type: "text",
              validator: validatePresent,
            },
          ],
          [
            {
              label: "Current Employer",
              name: "currentEmployer",
              type: "text",
              required: false,
            },
            { label: "Phone", name: "phone", type: "text", required: false },
            {
              label: "Country",
              name: "country",
              type: "select",
              validator: validatePresent,
              options: countries,
            },
          ],
          [
            {
              label: "Province/State",
              name: "province",
              type: "select",
              validator: validateState,
              options: state => state.country
                ? states.filter(x => x.country === state.country)
                : states,
              required: state => isStateRequired(state.country)
            },
            {
              label: "City",
              name: "city",
              type: "autocomplete",
              validator: validatePresent,
            },
          ],
          [
            {
              label: "Email Address",
              name: "email",
              type: "text",
              validator: validateEmail,
            },
          ],
        ],
      },
      {
        title: "Pre-Screening Questions",
        rows: [],
      },
      {
        title: "Experience",
        rows: [
          [
            {
              label: "Highest College Degree",
              name: "highestCollegeDegree",
              type: "text",
              validator: validatePresent,
            },
            {
              label: "Most Recent Job Title",
              name: "mostRecentJobTitle",
              type: "text",
              validator: validatePresent,
            },
          ],
          [
            {
              label: "Minimum Salary",
              name: "minSalary",
              type: "number",
              validator: validateNumber,
            },
            {
              label: "Maximum Salary",
              name: "maxSalary",
              type: "number",
              validator: validateNumber,
            },
          ],
        ],
      },
      {
        title: "Supporting Document",
        rows: [
          [
            {
              label: "Note From Candidate",
              name: "candidateNote",
              type: "textarea",
              required: false,
            },
            {
              label: "Note From Recruiter",
              name: "recruiterNote",
              type: "textarea",
              required: false,
            },
          ],
          [
            {
              label: "LinkedIn Profile",
              name: "linkedInProfile",
              type: "text",
              required: false,
            },
            {
              label: "Resume on file",
              name: "resume",
              type: "file",
              validator: validatePresent,
            },
          ],
        ],
      },
    ],
  };

  _goBack = () => this.props.history.goBack();

  _renderForm({ title, sections, onSubmit, onCancel }) {
    return (
      <Form onSubmit={onSubmit}>
        <div>
          <Card.Header className="bg-white">{title}</Card.Header>
          <Card.Body>
            {this._renderFormError()}
            {sections.map((section, idx) => this._renderSection(section, idx))}
          </Card.Body>
          <Card.Footer className="text-right bg-white">
            <Button variant="danger" className="ml-2" onClick={onCancel}>
              Cancel
            </Button>
            <Button className="ml-2" type="submit">
              Send
            </Button>
          </Card.Footer>
        </div>
      </Form>
    );
  }

  _renderFormError() {
    if (!this.props.formJobError) return null;
    return <Alert variant="danger">{this.props.formJobError}</Alert>;
  }

  _renderSection(section, idx) {
    return (
      <React.Fragment key={idx}>
        {idx > 0 && <hr />}
        <Form.Row className="mb-2">
          <Col>
            <h5>{section.title}</h5>
          </Col>
        </Form.Row>
        {section.rows.map((row, index) => {
          return (
            <Form.Row key={index} className="mb-2">
              {row.map((field) => this._renderField(field))}
            </Form.Row>
          );
        })}
      </React.Fragment>
    );
  }

  _validateField = (validator) => (e) => {
    return this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value, this.state),
      },
    });
  };

  _onChange = (e) => {
    let extra = {}, cb = function() {}
    if (e.target.name === "province") {
      extra = { city: "" }
      cb = this._fetchCities
    } else if (e.target.name === "country") {
      extra = { city: "", province: "", cities: [] }
    }
    this.setState({
      [e.target.name]:
        e.target.type === "number" ? Number(e.target.value) : e.target.value,
      ...extra
    }, cb);
  };

  _fetchCities = async(e) => {
    const {data} = await axios.get(
      `${process.env.PUBLIC_URL}/api/v1/data/cities`,
      {
        params: {state: this.state.province}
      }
    );
    this.setState({
      cities: data.map(x => ({
        value: x.name, //DO not use id
        text: x.name
      }))
    })
  }

  _renderField({
    label,
    name,
    validator,
    type,
    instructions,
    placeholder,
    required = true,
    ...extra
  }) {
    const isRequired = typeof required === 'function' ? required(this.state) : required;
    const validationError = this.state.validationErrors[name];
    const props = {
      name,
      value: this.state[name] || "",
      isInvalid: !!validationError,
      onChange: this._onChange,
      onBlur: validator ? this._validateField(validator) : undefined,
      placeholder,
    };
    return (
      <Col sm={6} key={name}>
        <div>
          <Form.Label>
            {label}{" "}
            {isRequired ? <span className="required-field">*</span> : null}
          </Form.Label>
          {instructions && (
            <small className="font-italic d-block">{instructions}</small>
          )}
        </div>
        <div>
          {this._renderControl(type, props, extra, validationError)}
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </div>
      </Col>
    );
  }

  _renderControl(type, props, extra, validationError) {
    switch (type) {
      case "text":
        return <Form.Control type="text" {...props} />;
      case "number":
        return <Form.Control type="number" {...props} />;
      case "textarea":
        return <Form.Control as="textarea" rows={3} {...props} />;
      case "select": {
        const { options } = extra;
        let optionsArray = typeof options === 'function' ? options(this.state) : options;
        if (props.name === "city") {
          optionsArray = this.state.cities
        }
        return (
          <Form.Control as="select" {...props}>
            <option />
            {optionsArray.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </Form.Control>
        );
      }
      case "file":
        return <FileUpload {...props} />;
      case "autocomplete":
        //TODO adapt this to be used by more than one field
        return (
          <Autocomplete
            getItemValue={item => item.value}
            items={this.state.city
              ? this.state.cities.filter(x => x.text.startsWith(this.state.city))
              : this.state.cities
            }
            renderItem={(item, isHighlighted) =>
              <div key={item.value} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
                {item.text}
              </div>
            }
            renderInput={inputProps =>
              <>
                <Form.Control {...inputProps}
                              name={props.name}
                              type="text" autoComplete="new-password" isInvalid={props.isInvalid}
                              onBlur={e => {
                                e.persist()
                                props.onBlur(e)
                                inputProps.onBlur(e)
                              }}/>
                <Form.Control.Feedback type="invalid">
                  {validationError}
                </Form.Control.Feedback>
              </>
            }
            renderMenu={(items, value, style) =>
              <div style={{ ...style, ...autoCompleteMenuStyle, maxHeight: `calc(100vmin - ${style.top}px)` }}
                   children={items}
              />
            }
            value={this.state.city}
            onChange={(e, value) =>
              props.onChange({target: {name: props.name, value}})
            }
            onSelect={value =>
              props.onChange({target: {name: props.name, value}})
            }
            wrapperStyle={{
              display: 'block'
            }}
          />
        )
      default:
        return null;
    }
  }

  _validateForm = (form, sections) => {
    const validators = sections.reduce((validators, section) => {
      for (const row of section.rows) {
        for (const field of row) {
          validators[field.name] = field.validator;
        }
      }
      return validators;
    }, {});
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      const validator = validators[field.name];
      const error = validator && validator(field.value, this.state);
      if (error) errors++;
      validationErrors[field.name] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  _postCandidateDetails = async (e) => {
    const { job, saveCandidate } = this.props;
    const candidate = {
      ...this.state,
      jobId: job.jobId,
      response: Object.keys(this.state)
        .filter((x) => x.startsWith("response["))
        .map((x) => this.state[x])
        .join("\n"),
    };
    e.preventDefault();
    if (this._validateForm(e.target, this._candidateDetailsForm.sections)) {
      try {
        await saveCandidate(candidate);
        toast.success(
          "You have successfully submitted a resume, make sure your candidate accepts the confirmation email. An Account Manager from RNR will contact you once an interview request is made."
        );
        this._goBack();
      } catch (e) {
        toast.error(
          (e.response && e.response.data && e.response.data.message) || e.message
        );
        window.scrollTo(0, 0);
        console.error(e);
      }
    }
  };

  async componentDidMount() {
    const {
      findCandidate,
      findJob,
      match: {
        params: { jobId, candidateId },
      },
    } = this.props;

    await findJob({ jobId });
    if (this.props.job.questions && this.props.job.questions.length > 0) {
      let i = 0;
      this._candidateDetailsForm.sections[1].fields = this.props.job.questions
        .split("\n")
        .map((question) => {
          return {
            label: question,
            name: `response[${i++}]`,
            type: "text",
            validator: validatePresent,
            key: question,
          };
        });
    } else {
      this._candidateDetailsForm.sections.splice(1, 1);
    }
    this.forceUpdate();

    if (candidateId && candidateId !== "new") {
      await findCandidate({ jobId, candidateId });
      this.setState({
        ...this.props.candidate,
      });
    }
  }

  render() {
    return (
      <div className="justify-content-md-center alone-modal-post">
        {this._renderForm({
          ...this._candidateDetailsForm,
          onSubmit: this._postCandidateDetails,
          onCancel: this._goBack,
        })}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    job: state.jobs.job,
    candidate: state.candidates.candidate,
    formCandidateError: state.candidates.formCandidateError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        saveCandidate,
        findCandidate,
        findJob,
      },
      dispatch
    )
)(CandidateDetailsPage);
