import {
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_COMPLETED,
    GET_CONVERSATIONS_FAILED,
    GET_MESSAGES,
    GET_MESSAGES_COMPLETED,
    GET_MESSAGES_FAILED,
    SEND_MESSAGE,
    SEND_MESSAGE_COMPLETED,
    SEND_MESSAGE_FAILED,
    NEW_MESSAGE,
    CONVERSATION_UPDATE,
    READ_CONVERSATION_COMPLETED
} from "../actions/conversation";
import { PAGE_INIT } from "../actions/pages";

export const defaultState = Object.freeze({
    currentConversationId: "",
    gettingConversations: false,
    conversations: [],
    getConversationsError: "",
    gettingMessages: false,
    messages: [],
    getMessagesError: "",
    sendingMessage: false,
    sendMessageError: ""
});

const conversationsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case PAGE_INIT: {
            return defaultState;
        }
        case GET_CONVERSATIONS: {
            return {
                ...state,
                gettingConversations: true,
                findRecruitersError: ""
            };
        }
        case GET_CONVERSATIONS_COMPLETED: {
            return {
                ...state,
                gettingConversations: false,
                conversations: action.payload
            };
        }
        case GET_CONVERSATIONS_FAILED: {
            return {
                ...state,
                gettingConversations: false,
                getConversationsError: action.payload.message
            };
        }
        case GET_MESSAGES: {
            return {
                ...state,
                gettingMessages: true,
                getMessagesError: "",
                currentConversationId: action.payload.conversationId
            };
        }
        case GET_MESSAGES_COMPLETED: {
            return {
                ...state,
                gettingMessages: false,
                messages: action.payload
            };
        }
        case GET_MESSAGES_FAILED: {
            return {
                ...state,
                gettingMessages: false,
                getMessagesError: action.payload.message
            };
        }
        case SEND_MESSAGE: {
            return {
                ...state,
                sendingMessage: true,
                sendMessageError: ""
            };
        }
        case SEND_MESSAGE_COMPLETED: {
            return {
                ...state,
                sendingMessage: false
            };
        }
        case SEND_MESSAGE_FAILED: {
            return {
                ...state,
                sendingMessage: false,
                sendMessageError: action.payload.message
            };
        }
        case NEW_MESSAGE: {
            return {
                ...state,
                messages: state.messages.concat([{conversationId: state.currentConversationId, ...action.payload}])
            };
        }
        case CONVERSATION_UPDATE: {
            const {conversationId, changedDate} = action.payload;
            console.log(changedDate, new Date(changedDate));
            const {conversations, currentConversationId, messages} = state;
            const updateConversation = (conversation) => {
                if (conversation.conversationId === conversationId)
                {
                    let updaterId;
                    if (currentConversationId === conversationId) {
                        updaterId = messages[messages.length - 1].senderId;
                    } else {
                        updaterId = conversation.ownerId;
                    }
                    conversation = {
                        ...conversation,
                        lastUpdateDate: changedDate,
                        isEmpty: false,
                        [conversation.ownerId === updaterId ? 'readByOther' : 'readByOwner']: false,
                    };
                }
                return conversation;
            }
            return {
                ...state,
                conversations: conversations
                  .map(updateConversation)
                  .sort((a, b) => {
                      const prevDate = new Date(a.lastUpdateDate);
                      const nextDate = new Date(b.lastUpdateDate);
                      console.log(prevDate, a.lastUpdateDate, nextDate, b.lastUpdateDate)
                      if (prevDate < nextDate) {
                          return 1
                      } else if (prevDate > nextDate) {
                          return -1
                      }
                      return 0
                  })
            }
        }
        case READ_CONVERSATION_COMPLETED: {
            const {conversationId, byOwner} = action.payload;
            return {
                ...state,
                conversations: state.conversations.map(c =>
                  c.conversationId === conversationId
                    ? {
                        ...c,
                        [byOwner ? 'readByOwner' : 'readByOther']: true,
                    } : c
                )
            }
        }
        default:
            return state;
    }
};

export default conversationsReducer;
