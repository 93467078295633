function CandidateStatus({ candidate }) {
    const {
        candidateRejectedByEmployer, candidateInvitedByEmployer, verified, acceptedByAdmin, employerAgreedOnLicenseTerms,
    } = candidate;
    if (candidateInvitedByEmployer) return "Invited";
    if (candidateRejectedByEmployer) return "Rejected";
    if (employerAgreedOnLicenseTerms) return "Verified and terms signed";
    if (acceptedByAdmin === true) return "Verified";
    // Only visible to admin/recruiter from here
    if (acceptedByAdmin === false) return "Rejected by admin";
    if (!verified) return "Awaiting email verification";
    return "Awaiting admin approval";
}

export default CandidateStatus;
