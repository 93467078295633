import { command, find, findOne } from "../utils/api";

const RECRUITERS_ACTION_URL = `${process.env.PUBLIC_URL}/api/v1/recruiters`;

export const RECRUITER_SIGN_UP = "RECRUITER_SIGN_UP";
export const RECRUITER_SIGN_UP_COMPLETED = "RECRUITER_SIGN_UP_COMPLETED";
export const RECRUITER_SIGN_UP_FAILED = "RECRUITER_SIGN_UP_FAILED";

export const signUpRecruiter = command(
  RECRUITERS_ACTION_URL + "/signUpRecruiter",
  RECRUITER_SIGN_UP,
  RECRUITER_SIGN_UP_COMPLETED,
  RECRUITER_SIGN_UP_FAILED
);

export const RECRUITER_VERIFY = "RECRUITER_VERIFY";
export const RECRUITER_VERIFY_COMPLETED = "RECRUITER_VERIFY_COMPLETED";
export const RECRUITER_VERIFY_FAILED = "RECRUITER_VERIFY_FAILED";

export const verifyRecruiter = command(
  RECRUITERS_ACTION_URL + "/verifyRecruiter",
  RECRUITER_VERIFY,
  RECRUITER_VERIFY_COMPLETED,
  RECRUITER_VERIFY_FAILED
);

export const RECRUITER_APPROVE = "RECRUITER_APPROVE";
export const RECRUITER_APPROVE_COMPLETED = "RECRUITER_APPROVE_COMPLETED";
export const RECRUITER_APPROVE_FAILED = "RECRUITER_APPROVE_FAILED";

export const approveRecruiter = command(
  RECRUITERS_ACTION_URL + "/approveRecruiter",
  RECRUITER_APPROVE,
  RECRUITER_APPROVE_COMPLETED,
  RECRUITER_APPROVE_FAILED
);

export const INVITE_RECRUITER = "INVITE_RECRUITER";
export const INVITE_RECRUITER_COMPLETED = "INVITE_RECRUITER_COMPLETED";
export const INVITE_RECRUITER_FAILED = "INVITE_RECRUITER_FAILED";

export const inviteRecruiter = command(
  RECRUITERS_ACTION_URL + "/inviteRecruiter",
  INVITE_RECRUITER,
  INVITE_RECRUITER_COMPLETED,
  INVITE_RECRUITER_FAILED
);

export const CREATE_RECRUITER = "CREATE_RECRUITER";
export const CREATE_RECRUITER_COMPLETED = "CREATE_RECRUITER_COMPLETED";
export const CREATE_RECRUITER_FAILED = "CREATE_RECRUITER_FAILED";

export const createRecruiter = command(
  RECRUITERS_ACTION_URL + "/createRecruiterAccount",
  CREATE_RECRUITER,
  CREATE_RECRUITER_COMPLETED,
  CREATE_RECRUITER_FAILED
);

export const CLEAR_INVITATION_ERRORS = "CLEAR_INVITATION_ERRORS";

export const clearInvitationErrors = () => dispatch => {
  dispatch({ type: CLEAR_INVITATION_ERRORS });
};

export const FIND_RECRUITERS = "FIND_RECRUITERS";
export const FIND_RECRUITERS_COMPLETED = "FIND_RECRUITERS_COMPLETED";
export const FIND_RECRUITERS_FAILED = "FIND_RECRUITERS_FAILED";

export const findRecruiters = find(
  RECRUITERS_ACTION_URL + "/find",
  FIND_RECRUITERS,
  FIND_RECRUITERS_COMPLETED,
  FIND_RECRUITERS_FAILED
);

export const FIND_RECRUITER = "FIND_RECRUITER";
export const FIND_RECRUITER_COMPLETED = "FIND_RECRUITER_COMPLETED";
export const FIND_RECRUITER_FAILED = "FIND_RECRUITER_FAILED";

export const findRecruiter = findOne(
  RECRUITERS_ACTION_URL + "/find",
  FIND_RECRUITER,
  FIND_RECRUITER_COMPLETED,
  FIND_RECRUITER_FAILED
);

export const UPDATE_RECRUITER_ADMIN_NOTES = 'UPDATE_RECRUITER_ADMIN_NOTES';
export const UPDATE_RECRUITER_ADMIN_NOTES_COMPLETED = 'UPDATE_RECRUITER_ADMIN_NOTES_COMPLETED';
export const UPDATE_RECRUITER_ADMIN_NOTES_FAILED = 'UPDATE_RECRUITER_ADMIN_NOTES_FAILED';

export const updateRecruiterAdminNotes = command(
  RECRUITERS_ACTION_URL + "/updateAdminNotes",
  UPDATE_RECRUITER_ADMIN_NOTES,
  UPDATE_RECRUITER_ADMIN_NOTES_COMPLETED,
  UPDATE_RECRUITER_ADMIN_NOTES_FAILED
);

export const FIND_LOGGED_RECRUITER = "FIND_LOGGED_RECRUITER";
export const FIND_LOGGED_RECRUITER_COMPLETED = "FIND_LOGGED_RECRUITER_COMPLETED";
export const FIND_LOGGED_RECRUITER_FAILED = "FIND_LOGGED_RECRUITER_FAILED";

export const findLoggedRecruiter = findOne(
    RECRUITERS_ACTION_URL + "/findLogged",
    FIND_LOGGED_RECRUITER,
    FIND_LOGGED_RECRUITER_COMPLETED,
    FIND_LOGGED_RECRUITER_FAILED
);