import axios from "axios";
import auth from "./auth";

export const returnTextForArrayValue = (array, value) => {
  const val = array.find((x) => x.value === value);
  return typeof val !== "undefined" ? val.text || "" : "";
};

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function downloadResume(e, fileName) {
  e.stopPropagation();
  await axios
    .get(`${process.env.PUBLIC_URL}/api/v1/files/download`, {
      responseType: "blob",
      params: { fileName },
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export async function startConversation(jobId, history, e) {
  e.stopPropagation();
  const createResult = await axios.post(
    `${process.env.PUBLIC_URL}/api/v1/conversations/createConversation`,
    {
      jobId,
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    }
  );
  history.push(`/chat/${createResult.data.conversationId}`);
}
