import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findEmployers, removeEmployer } from "../actions/employers";
import {
  FormControl,
  Col,
  Container,
  Row,
  Modal,
  OverlayTrigger,
  Tooltip,
  Alert,
  ButtonGroup,
  Table
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {
  Button,
} from "react-bootstrap";

class ListOfEmployers extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,

    employers: PropTypes.array.isRequired,
    findEmployers: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    removeEmployer: PropTypes.func.isRequired,
    removeEmployerError: PropTypes.string.isRequired,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 25,
    search: "",
    showDeleteModal: false,
    employerIdForModal: "",
    deleteTooltipVisible: "",
  };
  componentDidMount() {
    this.findEmployers();
  }
  findEmployers = async () => {
    const search = this.state.search;
    if (!search) {
      await this.props.findEmployers({
        offset: this.state.pageNr,
        limit: this.state.perPage,
      });
    } else {
      await this.props.findEmployers({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
      });
    }
  };

  _removeEmployer = async() => {
    try {
      console.log(this.props.removeEmployerError);
      const { employerIdForModal: employerId } = this.state;
      await this.props.removeEmployer({ employerId });
      console.log(this.props.removeEmployerError);
      await this.onDeleteModalHide();
      await this.findEmployers();
    } catch (e) {}
  };
  openDeleteModal = async (employerId) => {
    this.setState({
      showDeleteModal: true,
      employerIdForModal: employerId,
    });
  };
  onDeleteModalHide = async () => {
    this.setState({
      deleteTooltipVisible: false,
      showDeleteModal: false,
      employerIdForModal: "",
    });
  };

  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findEmployers();
    });
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findEmployers();
        }, 900))
    );
  };
  _onToggleDeleteTooltip = (show, userId) => {
    // JD: hack to prevent tooltip from showing up again after modal close
    if (this.state.deleteTooltipVisible === false) {
      this.setState({ deleteTooltipVisible: '' });
    } else {
      this.setState({ deleteTooltipVisible: (show && userId) || "" });
    }
  };

  render() {
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="9" className="padding-none">
              <h4>Employers</h4>
            </Col>
            <Col sm="3" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Verified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.employers.map((employer) => (
                <tr className="tr-main" key={employer.employerId}>
                  <td>{employer.firstName}</td>
                  <td>{employer.lastName}</td>
                  <td>{employer.companyName}</td>
                  <td>{employer.email}</td>
                  <td>{employer.phoneNumber}</td>
                  <td>{employer.verified ? "Yes" : "No"}</td>
                  <td>
                    <ButtonGroup className="btn-group-actions">
                      <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 250 }}
                          overlay={<Tooltip id="delete-user-tooltip">Delete</Tooltip>}
                          show={this.state.deleteTooltipVisible === employer.employerId}
                          onToggle={(show) => this._onToggleDeleteTooltip(show, employer.employerId)}
                      >
                        <Button
                            size="sm"
                            variant="danger"
                            onClick={() => this.openDeleteModal(employer.employerId)}
                            className="material-icons"
                        >
                          delete
                        </Button>
                      </OverlayTrigger>
                      </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal
              centered
              show={this.state.showDeleteModal}
              onHide={this.onDeleteModalHide}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Employer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="danger" hidden={!this.props.removeEmployerError}>
                {this.props.removeEmployerError}
              </Alert>
              <p>Are you sure you want to delete this employer?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.onDeleteModalHide}>
                Close
              </Button>
              <Button variant="primary" onClick={this._removeEmployer}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.totalCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    employers: state.employers.employers,
    totalCount: state.employers.totalCount,
    removeEmployerError: state.employers.removeEmployerError,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findEmployers: findEmployers,
        removeEmployer
      },
      dispatch
    )
)(ListOfEmployers);
