import { command, find, findOne } from "../utils/api";

const CANDIDATES_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/candidates`;

export const SAVE_CANDIDATE_POSTING = "SAVE_CANDIDATE_POSTING";
export const SAVE_CANDIDATE_POSTING_COMPLETED =
  "SAVE_CANDIDATE_POSTING_COMPLETED";
export const SAVE_CANDIDATE_POSTING_FAILED = "SAVE_CANDIDATE_POSTING_FAILED";

export const saveCandidate = command(
  CANDIDATES_BASE_URL + "/submitCandidate",
  SAVE_CANDIDATE_POSTING,
  SAVE_CANDIDATE_POSTING_COMPLETED,
  SAVE_CANDIDATE_POSTING_FAILED
);

export const CANDIDATE_VERIFY = "CANDIDATE_VERIFY";
export const CANDIDATE_VERIFY_COMPLETED = "CANDIDATE_VERIFY_COMPLETED";
export const CANDIDATE_VERIFY_FAILED = "CANDIDATE_VERIFY_FAILED";

export const verifyCandidate = command(
  CANDIDATES_BASE_URL + "/verifyCandidate",
  CANDIDATE_VERIFY,
  CANDIDATE_VERIFY_COMPLETED,
  CANDIDATE_VERIFY_FAILED
);

export const FIND_CANDIDATES = "FIND_CANDIDATES";
export const FIND_CANDIDATES_COMPLETED = "FIND_CANDIDATES_COMPLETED";
export const FIND_CANDIDATES_FAILED = "FIND_CANDIDATES_FAILED";

export const findCandidates = find(
  CANDIDATES_BASE_URL + "/find",
  FIND_CANDIDATES,
  FIND_CANDIDATES_COMPLETED,
  FIND_CANDIDATES_FAILED
);

export const FIND_INTERVIEW_TIMES = "FIND_INTERVIEW_TIMES";
export const FIND_INTERVIEW_TIMES_COMPLETED = "FIND_INTERVIEW_TIMES_COMPLETED";
export const FIND_INTERVIEW_TIMES_FAILED = "FIND_INTERVIEW_TIMES_FAILED";

export const findInterviewTimes = find(
  CANDIDATES_BASE_URL + "/findInterviewTimes",
  FIND_INTERVIEW_TIMES,
  FIND_INTERVIEW_TIMES_COMPLETED,
  FIND_INTERVIEW_TIMES_FAILED
);

export const FIND_CANDIDATE = "FIND_CANDIDATE";
export const FIND_CANDIDATE_COMPLETED = "FIND_CANDIDATE_COMPLETED";
export const FIND_CANDIDATE_FAILED = "FIND_CANDIDATE_FAILED";

export const findCandidate = findOne(
  "employer",
  CANDIDATES_BASE_URL + "/find",
  FIND_CANDIDATE,
  FIND_CANDIDATE_COMPLETED,
  FIND_CANDIDATE_FAILED
);

export const CANDIDATE_ACCEPT_TERMS = "CANDIDATE_ACCEPT_TERMS";
export const CANDIDATE_ACCEPT_TERMS_COMPLETED =
  "CANDIDATE_ACCEPT_TERMS_COMPLETED";
export const CANDIDATE_ACCEPT_TERMS_FAILED = "CANDIDATE_ACCEPT_TERMS_FAILED";

export const acceptTermsForCandidate = command(
  CANDIDATES_BASE_URL + "/acceptTermsForViewingCandidate",
  CANDIDATE_ACCEPT_TERMS,
  CANDIDATE_ACCEPT_TERMS_COMPLETED,
  CANDIDATE_ACCEPT_TERMS_FAILED
);

export const CANDIDATE_REJECT = "CANDIDATE_REJECT";
export const CANDIDATE_REJECT_COMPLETED = "CANDIDATE_REJECT_COMPLETED";
export const CANDIDATE_REJECT_FAILED = "CANDIDATE_REJECT_FAILED";

export const rejectCandidate = command(
  CANDIDATES_BASE_URL + "/rejectCandidateByEmployer",
  CANDIDATE_REJECT,
  CANDIDATE_REJECT_COMPLETED,
  CANDIDATE_REJECT_FAILED
);

export const CANDIDATE_INVITE = "CANDIDATE_INVITE";
export const CANDIDATE_INVITE_COMPLETED = "CANDIDATE_INVITE_COMPLETED";
export const CANDIDATE_INVITE_FAILED = "CANDIDATE_INVITE_FAILED";

export const inviteCandidate = command(
  CANDIDATES_BASE_URL + "/inviteCandidateByEmployer",
  CANDIDATE_INVITE,
  CANDIDATE_INVITE_COMPLETED,
  CANDIDATE_INVITE_FAILED
);
