import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert, Button, Card, Form, InputGroup, OverlayTrigger, Popover, Row,
} from 'react-bootstrap';
import { validateEmail, validatePassword } from "../utils/validators";
import { connect } from "react-redux";
import { login } from "./actions";
import { bindActionCreators } from "redux";
import Auth from "../utils/auth";
import "./index.scss";
import logo from "../assets/img/logo.png";
import { toast } from "react-toastify";
import {Link} from "react-router-dom"
import SignInWithLinkedIn from './SignInWithLinkedIn';

const validators = {
  email: validateEmail,
  password: validatePassword,
};

function redirectIfAuthenticated(token, history) {
  if (!token) return;
  const auth = Auth.getInstance();
  const scope = auth.getScope();
  if (auth.isAuthenticated()) {
    if (scope === "admin") {
      history.push(`recruiter/home`);
    } else if (scope) {
      history.push(`${scope}/home`);
    }
  }
}

class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    validationErrors: {},
    textTitle: 'WELCOME!',
    textContent: 'Innovating in the recruitment space with RNR, our MISSION to provide a recruitment platform that hires faster, better and smarter using technology',
    submitted: false,
  };

  constructor(props) {
    super(props);

    const search = props.location.search.slice(1);
    if (search.startsWith("error=")) {
      const msg = decodeURIComponent(search.slice(6));
      toast.error(msg);
    }
    else if (search.startsWith("verifyFail=")) {
      const msg = decodeURIComponent(search.slice(11));
      toast.error(msg);
    }
    else if (search.startsWith("verifySuccess=")) {
      const msg = search.slice(14);
      toast.success(msg);
      this.state.textTitle = 'ACCOUNT VERIFIED';
      this.state.textContent = 'Your account has been verified. You can now login.';
    }
  }

  componentDidMount() {
    const { token } = this.props;
    redirectIfAuthenticated(token, this.props.history);
  }

  componentDidUpdate(prevProps) {
    const { token, history } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (token && token !== prevProps.token) history.push(from);
    redirectIfAuthenticated(token, this.props.history);
  }

  _validateField = (validator) => (e) =>
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value),
      },
    });

  _validateForm = (form) => {
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      const validator = validators[field.name];
      const error = validator && validator(field.value);
      if (error) errors++;
      validationErrors[field.name] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  _renderFormError() {
    if (!this.props.error || !this.state.submitted) return null;
    return (
      <Alert variant="danger">
        {this.props.error}
      </Alert>
    );
  }

  _renderInputGroup(ariaLabel, inputType, placeHolder, fieldName) {
    const validationError = this.state.validationErrors[fieldName];
    const isInvalid = !!validationError;
    const validator = validators[fieldName];
    const onBlur = validator ? this._validateField(validator) : undefined;
    return (
      <Form.Row>
        <Form.Group controlId={fieldName}>
          <InputGroup>
            <Form.Label>{placeHolder}</Form.Label>
            <Form.Control
              type={inputType}
              placeholder={placeHolder}
              name={fieldName}
              value={this.state[fieldName]}
              onChange={this._onChange}
              onBlur={onBlur}
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback type="invalid">
              {validationError}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    );
  }

  _onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  _onSubmit = async (e) => {
    e.preventDefault();
    if (this._validateForm(e.target)) {
      const { email: username, password } = this.state;
      this.props.login({ username, password });
    }
    this.setState({ submitted: true });
  };

  render() {
    return (
      <Row className="login-page-row justify-content-md-center">
        <div className="box-left">
          <div><img alt="logo" src={logo} /></div>
          <div className="font-weight-bold login-info-title">
            {this.state.textTitle}
          </div>
          <div className="login-info-content">
            {this.state.textContent}
          </div>
        </div>
        <div className="box-right">
          <Card className="mb-4">
            <Card.Header className="text-center p-2">Login</Card.Header>
            <Card.Body>
              {this._renderFormError()}
              <Form className="login-page" onSubmit={this._onSubmit}>
                {this._renderInputGroup("e-mail", "text", "Email", "email")}
                {this._renderInputGroup(
                  "lock",
                  "password",
                  "Password",
                  "password"
                )}
                <div className="btns-wrapper-login">
                  <Link to="/password-reset">
                    Forgot password?
                  </Link>
                  <Button variant="primary" type="submit" size={"lg"}>
                    Log in
                  </Button>
                </div>
                <OverlayTrigger
                  trigger="focus"
                  placement="left"
                  overlay={
                    <Popover id="popover-trouble" style={{minWidth: '400px'}}>
                      <Popover.Title as="h3">Troubleshooting</Popover.Title>
                      <Popover.Content>
                        <ul>
                          <li className="mb-2">
                            Have you verified your account?<br />
                            <i>A verification link has been sent to your email.</i>
                          </li>
                          <li className="mb-2">
                            Have you been approved by the administrator?<br />
                            <i>Recruiters account have to be approved first.
                            An email is sent when this process is done.</i>
                          </li>
                          <li className="mb-2">
                            Why can't I sign in with LinkedIn?<br />
                            <i>
                              Your LinkedIn email must match your RNR email otherwise a new account will be
                              created. Also an existing account must be verified and approved first.
                            </i>
                          </li>
                        </ul>
                        <a
                          href="https://inforecruitnrefer.ca/im-a-recruiter-1"
                          className="float-right"
                        >
                          Recruiter F.A.Q.
                        </a>
                        <a
                          href="https://inforecruitnrefer.ca/im-an-employer-1"
                        >
                          Employer F.A.Q.
                        </a>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button variant="link">
                    Having trouble?
                  </Button>
                </OverlayTrigger>
              </Form>
            </Card.Body>
            <Card.Footer className="text-center">
              New to Recruit N Refer?{" "}
              <Link to="/sign-up">
                Sign Up
              </Link>
            </Card.Footer>
          </Card>
          <div className="text-center">
            <SignInWithLinkedIn style={{ cursor: 'pointer' }} />
          </div>
        </div>
      </Row>
    );
  }
}

LoginPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  loading: state.auth.loading,
  error: state.auth.error,
});
const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
