import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from "downloadjs";
import html2canvas from "html2canvas";

function toLines(text, {font, fontSize}, maxWidth) {
  const lines = [];

  for (const line of text.split('\n')) {
    if (font.widthOfTextAtSize(line, fontSize) <= maxWidth) {
      // this line is fine, keep it as is
      lines.push(line);
      continue;
    }
    // line is too long, split it
    const words = line.split(' ');
    let newLine = [];
    for (const word of words) {
      newLine.push(word);
      if (font.widthOfTextAtSize(newLine.join(' '), fontSize) <= maxWidth) continue;

      newLine.splice(-1);
      lines.push(newLine.join(' '));

      newLine = [word];
    }

    if (newLine.length > 0) {
      lines.push(newLine.join(' '));
    }
  }

  return lines;
}

async function drawHtml2Canvas(id) {
  const canvasToDraw = document.createElement('canvas');
  const el = document.getElementById(id);
  const canvas = await html2canvas(el);
  const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data;
  const width = canvas.width;
  const ratio = this.maxWidth / canvas.width;
  // console.log(el.offsetWidth, canvas.width, canvas.height, ratio);
  let height = Math.ceil((this.pos.y - this.pageMargin.bottom) / ratio);
  let offsetY = 0;
  while (offsetY < canvas.height) {
    // console.log(height, offsetY);
    // Are we outside the canvas
    if (height > (canvas.height - offsetY)) {
      height = canvas.height - offsetY;
    } else {
      const lineSize = width * 4;
      for (let i = (offsetY + height - 1) * lineSize; i > 0; i -= lineSize) {
        if (imageData.slice(i, i + lineSize).every(x => x === 255)) break;
        height--;
      }
    }
    // Get part of canvas to draw
    canvasToDraw.width = width;
    canvasToDraw.height = height;
    const ctx = canvasToDraw.getContext('2d');
    ctx.drawImage(canvas, 0, offsetY, width, height, 0, 0, width, height);
    offsetY += height;
    height = Math.ceil((this.size.height - (this.pageMargin.top + this.pageMargin.bottom)) / ratio);
    // Draw the canvas
    const imgDataUrl = canvasToDraw.toDataURL('image/png');
    const pdfImage = await this.page.doc.embedPng(imgDataUrl);
    const imgSize = pdfImage.scale(ratio);
    this.pos.y -= imgSize.height;
    this.page.drawImage(pdfImage, {...this.pos, ...imgSize});
    // Space after image and change page if required
    this.pos.y -= 16;
    await this.changePageIfNeeded(imgSize.height);
  }
}

async function drawText(text, {size, lineHeight, font}) {
  for (const line of toLines(text.replace(/\t/g, '    '), {font, fontSize: size}, this.maxWidth)) {
    const fontHeight = font.heightAtSize(size)
    await this.changePageIfNeeded(fontHeight);
    this.pos.y -= fontHeight;
    this.page.drawText(line, {...this.pos, size, lineHeight, font});
    this.pos.y -= (lineHeight - 1) * fontHeight;
  }
}

export async function downloadGeneratedPdf(sections) {
  // Load existing PDF template
  const res = await fetch("/RNR-Letterhead.pdf");
  const existingPdfBytes = await res.arrayBuffer();
  const existingPdfDoc = await PDFDocument.load(existingPdfBytes);

  // Create PDF document
  const pdfDoc = await PDFDocument.create();

  // Embed the Helvetica font
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  // Get sizes
  const templatePage = existingPdfDoc.getPage(0);
  const {width, height} = templatePage.getSize();
  const marginX = width / 16;
  const marginY = height / 8;

  // Copy the first page of the template document
  const getNewPage = async() => {
    const pages = await pdfDoc.copyPages(existingPdfDoc, [0]);
    return pages[0];
  };

  // Draw
  const helvetica = {
    normal: helveticaFont,
    bold: helveticaBoldFont,
  };
  const ctx = {
    page: await getNewPage(),
    pos: {x: marginX, y: height - marginY},
    size: {width, height},
    maxWidth: width - (marginX * 2),
    pageMargin: {
      top: marginY,
      bottom: marginY,
      left: marginX,
      right: marginX,
    },
    async changePageIfNeeded(h) {
      if (this.pos.y - h >= this.pageMargin.bottom) return;
      pdfDoc.addPage(this.page);
      this.page = await getNewPage();
      this.pos.y = height - marginY; // TODO fix me
    },
    fonts: {
      default: helvetica,
      'Helvetica': helvetica,
    },
    pointMultiplier: 1.2,
    styles: [],
  };

  const h5 = {size: 16, lineHeight: 2, font: helveticaBoldFont};
  const text = {size: 10, lineHeight: 1.5, font: helveticaFont};
  for (const section of sections) {
    const {header, body, id} = section;
    await drawText.call(ctx, header, h5);
    if (id) {
      await drawHtml2Canvas.call(ctx, id);
      continue;
    }
    if (!body) continue;
    for (const subSection of body) {
      const {header, content} = subSection;
      if (header) {
        await drawText.call(ctx, header, {...text, font: helveticaBoldFont});
      }
      await drawText.call(ctx, content.toString(), text);
      ctx.pos.y -= helveticaFont.heightAtSize(text.size);
    }
    ctx.pos.y -= helveticaFont.heightAtSize(text.size);
  }

  // Add page to generated document
  pdfDoc.addPage(ctx.page);

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Download the PDF
  download(pdfBytes, "job-posting.pdf", "application/pdf");
}