import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadFile } from "../actions/files";
import { Form, ProgressBar } from "react-bootstrap";

class FileUpload extends Component {
  static propTypes = {
    // props
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    // state
    progress: PropTypes.object.isRequired,
    // actions
    uploadFile: PropTypes.func.isRequired,
  };

  _onFileChange = (e) => {
    try {
      this.props.uploadFile({ file: e.target.files[0] });
    } catch (err) {
      //
    }
  };

  componentDidUpdate(prevProps) {
    const {
      progress: { filename },
      onChange,
      name,
    } = this.props;

    if (filename && filename !== prevProps.progress.filename)
      onChange({ target: { name, value: filename } });
  }

  render() {
    const {
      progress: { state, percentage, error },
      isInvalid,
      name,
    } = this.props;
    return (
      <div>
        <Form.Control
          name={name}
          type="file"
          onChange={this._onFileChange}
          isInvalid={isInvalid}
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        {state && !error && (
          <ProgressBar striped now={percentage} label={`${percentage}%`} />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    progress: state.files.progress,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        uploadFile,
      },
      dispatch
    )
)(FileUpload);
