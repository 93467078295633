import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import countries from "../data/countries";
import states from "../data/states";
import {toast} from "react-toastify";
import jobTypes from "../data/jobTypes";
import {
  acceptJobCastTerms,
  activeJobCast,
  changeFavoriteJobStatus,
  changeRetailTermForJob,
  findAllJobs,
  findCandidatesForJobByStatus,
  getJobCasts,
  removeJobCast,
} from "../actions/jobs";
import {findRetailTerms} from "../actions/retailTerms";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import jobFunctions from "../data/jobFunctions";
import {downloadResume, returnTextForArrayValue, startConversation,} from "../utils/commons";
import industries from "../data/industries";
import moment from "moment";
import terms from "../data/terms";
import Auth from "../utils/auth";
import Pagination from "react-js-pagination";
import {downloadGeneratedPdf} from "../utils/pdf"
import CandidateStatus from "./CandidateStatus";
import {findLoggedRecruiter} from "../actions/recruiters";

const SalaryDisplay = ({from, to}) => {
  if (from || to) {
    return `$ ${from || ''} - ${to || ''}`;
  }
  return "-";
}

/*
const JobFunctionFilter = ({active, onChange}) => (
  <DropdownButton id="recruiter-home-job-function-filter" title="Job Function Filter" drop="right">
    <Dropdown.Item as="button" active={active === 0} onClick={() => onChange(0)}>
      None
    </Dropdown.Item>
    {jobFunctions.map(({value, text}) => {
      const intValue = parseInt(value);
      return (
        <Dropdown.Item
          as="button" className="btn-sm" active={active === intValue} onClick={() => onChange(intValue)}
          key={value}
        >
          {text}
        </Dropdown.Item>
      );
    })}
  </DropdownButton>
);
*/

const popperConfig = {modifiers:[{name:'preventOverflow',options:{boundariesElement:'window'}}]};

class RecruiterHomePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired,
    changeRetailTermForJob: PropTypes.func.isRequired,
    findAllJobs: PropTypes.func.isRequired,
    getJobCasts: PropTypes.func.isRequired,
    removeJobCast: PropTypes.func.isRequired,
    activeJobCast: PropTypes.func.isRequired,
    acceptJobCastTerms: PropTypes.func.isRequired,
    userActiveJobCastsIds: PropTypes.array.isRequired,
    findCandidatesForJobByStatus: PropTypes.func.isRequired,
    findRetailTerms: PropTypes.func.isRequired,
    retailTerms: PropTypes.array.isRequired,
    itemsCount: PropTypes.number,
    changeFavoriteJobStatus: PropTypes.func.isRequired,
    findLoggedRecruiter: PropTypes.func.isRequired,
    loggedRecruiter: PropTypes.object.isRequired,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 25,
    search: "",
    showTerms: false,
    termForJobId: null,
    openedRow: null,
    showJobDetails: false,
    jobDetails: null,
    showEditRetailTerm: false,
    jobForRetailTerms: {},
    jobFunction: 0,
    onlyFavorites: false,
  };
  openRow = (e, id) => {
    e.stopPropagation();
    this.setState({
      openedRow: id,
    });
  };
  closeRow = (e) => {
    e.stopPropagation();
    this.setState({
      openedRow: null,
    });
  };
  _onSubmitCandidateClick = (jobId, event) => {
    if (event) event.stopPropagation();
    this.props.history.push("/candidate/" + jobId + "/new");
  };
  findAllJobs = async () => {
    const {search, jobFunction} = this.state;
    const orderBy = 'postDate DESC';
    if (!search) {
      await this.props.findAllJobs({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        byJobFunction: jobFunction,
        onlyFavorites: this.state.onlyFavorites,
        orderBy,
      });
    } else {
      await this.props.findAllJobs({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
        byJobFunction: jobFunction,
        onlyFavorites: this.state.onlyFavorites,
        orderBy,
      });
    }
  };
  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findAllJobs();
    });
  };
  componentDidMount() {
    this.findAllJobs();
    this.props.getJobCasts();
    const auth = Auth.getInstance();
    const userId = auth.getUserId();
    this.props.findLoggedRecruiter({ recruiterId: userId });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.jobFunction !== this.state.jobFunction) this.findAllJobs();
  }
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findAllJobs();
        }, 900))
    );
  };
  showEditRetailTerm = (job, scope) => {
    if (scope === "admin") {
      this.props.findRetailTerms();
    }
    this.setState({ showEditRetailTerm: true, jobForRetailTerms: job });
  };
  hideRetailTermsEdit = () => {
    this.setState({ showEditRetailTerm: false, jobForRetailTerms: {} });
  };
  changeRetailTerm = async (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.elements.retailTermId || !form.elements.retailTermId.value) {
      toast.error("You need to chose retail term");
      return;
    }
    const retailTermId = form.elements.retailTermId.value;
    await this.props.changeRetailTermForJob({
      jobId: this.state.jobForRetailTerms.jobId,
      retailTermId,
    });
    await this.findAllJobs();
    this.hideRetailTermsEdit();
  };
  acceptJobCast = async (e, jobId) => {
    e.stopPropagation();
    this.setState({ showTerms: true, termForJobId: jobId });
  };
  _agreeToTerms = async () => {
    try {
      await this.props.acceptJobCastTerms({
        terms: "",
      });
      await this.props.activeJobCast({
        jobId: this.state.termForJobId,
      });
      this.setState(
        { showTerms: false, termForJobId: null },
        () => { this.findAllJobs(); }
      );
    } catch (e) {
      console.error(e);
    }
  };

  _fetchCandidatesWithFilter = async (jobId, status) =>
    await this.props.findCandidatesForJobByStatus({ jobId, status });

  _onTermsHide = () => this.setState({ showTerms: false, termForJobId: null });
  _onJobDetailsHide = () =>
    this.setState({ showJobDetails: false, jobDetails: null });
  showJobDetails = (job) => {
    this.setState({ showJobDetails: true, jobDetails: jobToDetails(job) });
  };
  _onCandidateDetailsHide = () =>
    this.setState({
      showCandidateDetails: false,
      selectedCandidate: null,
      jobOfSelectedCandidate: null,
    });
  showCandidateDetails = (candidate, job) => {
    this.setState({
      showCandidateDetails: true,
      selectedCandidate: candidate,
      jobOfSelectedCandidate: job,
    });
  };
  pluralize(count) {
    if (count <= 1) {
      return "";
    }
    return "s";
  }
  renderJobDetailsRow = (label, value) => {
    return (
      <div className="job-details-row" key={label}>
        <span className="span-label">{label}</span>: {value}
      </div>
    );
  };
  renderCandidateDetails(job) {
    const recruiters = job.candidates.reduce((acc, candidate) => {
      const recruiterId = candidate.recruiter.recruiterId;
      if (!acc.find(r => r.recruiterId === recruiterId)) {
        acc.push(candidate.recruiter);
      }
      return acc;
    }, []);

    let adminView = null;
    const scope = Auth.getInstance().getScope();
    if (
      scope === 'employer'
      || (scope === 'recruiter' && !this.props.userActiveJobCastsIds.find((x) => x === job.jobId))
            || (scope === 'referralpartner' && !this.props.userActiveJobCastsIds.find((x) => x === job.jobId))
    ) {
      return (
        <div className="candidates-filter-cards">
          <div className="filterableCard">
            {job.totals.noOfCandidates} Candidate{this.pluralize(job.totals.noOfCandidates)}
          </div>
          <div className="filterableCard">
            {job.totals.noOfCandidatesSelectedForInterview} Selected for Interview
          </div>
          <div className="filterableCard">
            {job.totals.noOfRejectedCandidates} Rejected
          </div>
          <div className="filterableCard">
            {job.totals.noOfOffersMadeForCandidates} Offer{this.pluralize(job.totals.noOfOffersMadeForCandidates)} Made
          </div>
          <div className="filterableCard">
            {job.totals.noOfOffersAccepted} Offer{this.pluralize(job.totals.noOfOffersAccepted)} Accepted
          </div>
          <div className="filterableCard">
            {recruiters.length} Recruiter{this.pluralize(recruiters.length)}
          </div>
        </div>
      );
    } else if (scope === 'admin') {
      adminView = (
        <>
          <div
            className="candidates-filter-cards"
            style={{ fontWeight: '800', padding: '1em' }}
          >
            {recruiters.length} Recruiter{this.pluralize(recruiters.length)}
          </div>
          <Table>
            <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
            </thead>
            <tbody>
            {recruiters.length === 0 ? (
              <tr>
                <td colSpan={3} className="text-center">
                  <small>No recruiters</small>
                </td>
              </tr>
            ) : null}
            {recruiters.map(recruiter => (
              <tr>
                <td>{recruiter.name}</td>
                <td>{recruiter.email}</td>
                <td>{recruiter.phone}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </>
      )
    }

    return (
      <>
        <div
          className="candidates-filter-cards"
          style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
        >
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "all" ? "active" : ""
            }`}
            onClick={() => this._fetchCandidatesWithFilter(job.jobId, "all")}
          >
            <div className="text-center">
              {job.noOfCandidates} Candidate{this.pluralize(job.noOfCandidates)}
            </div>
            <div className="text-center">
              over {job.totals.noOfCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "selectedForInterview"
                ? "active"
                : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "selectedForInterview")
            }
          >
            <div className="text-center">
              {job.noOfCandidatesSelectedForInterview} Selected for Interview
            </div>
            <div className="text-center">
              over {job.totals.noOfCandidatesSelectedForInterview} total
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "rejected" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "rejected")
            }
          >
            <div className="text-center">
              {job.noOfRejectedCandidates} Rejected Candidate
              {this.pluralize(job.noOfRejectedCandidates)}
            </div>
            <div className="text-center">
              over {job.totals.noOfRejectedCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "offersMade" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersMade")
            }
          >
            <div className="text-center">
              {job.noOfOffersMadeForCandidates} Offer
              {this.pluralize(job.noOfOffersMadeForCandidates)} Made
            </div>
            <div className="text-center">
              over {job.totals.noOfOffersMadeForCandidates} total
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "offersAccepted" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersAccepted")
            }
          >
            <div className="text-center">
              {job.noOfOffersAccepted} Offer
              {this.pluralize(job.noOfOffersAccepted)} Accepted
            </div>
            <div className="text-center">
              over {job.totals.noOfOffersAccepted} total
            </div>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Candidate</th>
              <th>Date submitted</th>
              <th>City</th>
              <th>Province/State</th>
              <th>Salary Range</th>
              <th>Status</th>
              <th className="actions-transparent">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!job.candidates || job.candidates.length === 0 ? (
              <tr key="noCandidatesKey">
                <td colSpan={6} className="text-center">
                  <small>
                    {this.props.findingCandidatesForJob
                      ? "Loading..."
                      : "No candidates with specified filter"}
                  </small>
                </td>
              </tr>
            ) : (
              job.candidates.map((candidate) => (
                <tr key={candidate.candidateId}>
                  <td>
                    {candidate.firstName} {candidate.lastName}
                  </td>
                  <td>
                    {moment(candidate.postDate).isValid()
                      ? moment(candidate.postDate).format("YYYY-MM-DD hh:mm A")
                      : ""}
                  </td>
                  <td>
                    {candidate.city}
                  </td>
                  <td>
                    {candidate.province && states.find((x) => x.value === candidate.province).text}
                  </td>
                  <td>
                    {candidate.minSalary} - {candidate.maxSalary}
                  </td>
                  <td><CandidateStatus candidate={candidate} /></td>
                  <td>
                    <ButtonGroup className="btn-group-actions">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>View details</Tooltip>}
                      >
                        <Button
                          size="sm"
                          variant="light"
                          onClick={(e) =>
                            this.showCandidateDetails(candidate, job)
                          }
                          className="material-icons"
                        >
                          search
                        </Button>
                      </OverlayTrigger>
                      {candidate.resume ? (
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Download resume</Tooltip>}
                          top
                        >
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={(e) => downloadResume(e, candidate.resume)}
                            className="material-icons"
                          >
                            attachment
                          </Button>
                        </OverlayTrigger>
                      ) : null}{" "}
                    </ButtonGroup>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {adminView}
      </>
    );
  }

  _onJobFunctionFilterChange(jobFunction) {
    this.setState({jobFunction});
  }

  render() {
    const { loggedRecruiter } = this.props;
    const auth = Auth.getInstance();
    const scope = auth.getScope();
    const blocked = auth.getIsBlocked();
    const userId = auth.getUserId();
    const {
      jobDetails,
      selectedCandidate,
      jobOfSelectedCandidate,
      onlyFavorites,
    } = this.state;

    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="2" className="padding-none">
              <h4>Job Postings</h4>
            </Col>
            <Col sm="6" className="p-0 pr-2 text-right">
              <ButtonGroup>
                <Button
                  active={onlyFavorites}
                  onClick={this._onFavoriteFilterClick.bind(this)}
                >
                  <i className="material-icons">favorite</i>
                  Favorites
                </Button>
                {/*<JobFunctionFilter*/}
                {/*  active={this.state.jobFunction}*/}
                {/*  onChange={this._onJobFunctionFilterChange.bind(this)}*/}
                {/*/>*/}
              </ButtonGroup>
            </Col>
            <Col sm="4" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          {blocked ? (
            <Alert variant="danger">
              You are blocked to view job list, contact administrator
              <a href="mailto:info@recruitnrefer.ca">info@recruitnrefer.ca</a>
              to get more information
            </Alert>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th style={{ flex: 27 }}>Job Title</th>
                  <th style={{ flex: 10 }}>Job function</th>
                  <th style={{ flex: 14 }}>Company Name</th>
                  <th style={{ flex: 10 }}>Location</th>
                  <th style={{ flex: 8 }}>Salary</th>
                  <th style={{ flex: 8 }}>Post date</th>
                  <th style={{ flex: 6 }}>Exclusive</th>
                  <th style={{ flex: 17 }} className="actions-transparent">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.props.jobs.map((job) => (
                  <React.Fragment key={job.jobId}>
                    <tr className="tr-main">
                      <td
                        style={{ flex: 27 }}
                        className="hovering-td"
                        onClick={(e) =>
                          this.state.openedRow !== job.jobId
                            ? this.openRow(e, job.jobId)
                            : this.closeRow(e)
                        }
                      >
                        <div
                          style={{
                            width: "20px",
                            textAlign: "center",
                            marginRight: "5px",
                          }}
                        >
                          {this.state.openedRow === job.jobId ? (
                            <i className="material-icons">arrow_drop_up</i>
                          ) : (
                            <i className="material-icons">arrow_drop_down</i>
                          )}
                        </div>
                        {job.jobTitle}
                      </td>
                      <td style={{ flex: 10 }}>
                        {returnTextForArrayValue(jobFunctions, job.jobFunction)}
                      </td>
                      <td style={{ flex: 14 }}>{job.companyName}</td>
                      <td style={{ flex: 10 }}>{job.city}, {job.state}</td>
                      <td style={{ flex: 8 }}><SalaryDisplay from={job.salaryFrom} to={job.salaryTo} /></td>
                      <td style={{ flex: 8 }}>
                        {moment(job.postDate).isValid()
                          ? moment(job.postDate).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td style={{ flex: 6 }}>
                        {job.allowedRecruiterIds ? 'Yes' : 'No'}
                      </td>
                      <td style={{ flex: 17 }}>
                        {scope === "recruiter" || scope === "admin" || scope === "referralpartner" ? (
                          <ButtonGroup className="btn-group-actions">
                            {this.props.userActiveJobCastsIds.find(
                              (x) => x === job.jobId
                            ) ? (
                              <>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>Remove job cast</Tooltip>}
                                  popperConfig={popperConfig}
                                >
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      this.props.removeJobCast({
                                        jobId: job.jobId,
                                      })
                                    }
                                    className="material-icons"
                                  >
                                    delete
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>Submit a candidate</Tooltip>
                                  }
                                  popperConfig={popperConfig}
                                >
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    onClick={(e) =>
                                      this._onSubmitCandidateClick(job.jobId, e)
                                    }
                                    className="material-icons"
                                  >
                                    person_add
                                  </Button>
                                </OverlayTrigger>
                              </>
                            ) : (job.allowedRecruiterIds === null || job.allowedRecruiterIds.includes(userId)) ? (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Accept job cast</Tooltip>}
                                popperConfig={popperConfig}
                              >
                                <Button
                                  size="sm"
                                  variant="success"
                                  onClick={(e) =>
                                    this.acceptJobCast(e, job.jobId)
                                  }
                                  className="material-icons"
                                >
                                  done
                                </Button>
                              </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>View details</Tooltip>}
                              popperConfig={popperConfig}
                            >
                              <Button
                                size="sm"
                                variant="light"
                                onClick={(e) => this.showJobDetails(job)}
                                className="material-icons"
                              >
                                search
                              </Button>
                            </OverlayTrigger>
                            {scope === "recruiter" ? (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip>Ask a question to Employer</Tooltip>
                                }
                                popperConfig={popperConfig}
                              >
                                <Button
                                  size="sm"
                                  variant="warning"
                                  onClick={(e) =>
                                    startConversation(
                                      job.jobId,
                                      this.props.history,
                                      e
                                    )
                                  }
                                  className="material-icons"
                                >
                                  question_answer
                                </Button>
                              </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={loggedRecruiter.referrer=== true ? <Tooltip>Referral Partner’s Fees</Tooltip> : <Tooltip>Recruiter’s Fees</Tooltip>}
                              popperConfig={popperConfig}
                            >
                              <Button
                                size="sm"
                                variant="info"
                                onClick={(e) =>
                                  scope !== "employer"
                                    ? this.showEditRetailTerm(job, scope)
                                    : null
                                }
                                className="material-icons"
                              >
                                attach_money
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Favorite</Tooltip>}
                              popperConfig={popperConfig}
                            >
                              <Button
                                size="lg"
                                variant="custom"
                                onClick={() => this._changeFavoriteStatus(job)}
                                className="material-icons"
                              >
                                {job.favorite ? 'favorite' : 'favorite_border'}
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        ) : null}
                      </td>
                    </tr>
                    {this.state.openedRow === job.jobId ? (
                      <tr>
                        <td className="candidate-td">
                          {this.renderCandidateDetails(job)}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.itemsCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
        <Modal
          centered
          show={this.state.showEditRetailTerm}
          onHide={this.hideRetailTermsEdit}
        >
          <Modal.Header closeButton>
            {scope === "admin" ? (
              <Modal.Title>Change retail term for job</Modal.Title>
            ) : (loggedRecruiter.referrer=== true ?
                  <Modal.Title>Referral Partner's Fee</Modal.Title>
                : <Modal.Title>Recruiter’s Fee</Modal.Title>
            )}
          </Modal.Header>
          <Form onSubmit={this.changeRetailTerm}>
            {scope === "admin" ? (
              <Modal.Body>
                <Form.Control as="select" name="retailTermId">
                  <option />
                  {this.props.retailTerms.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Modal.Body>
            ) : (
              <Modal.Body>
                {
                  this.state.jobForRetailTerms.isFeePercent
                  ? `Your recruitment fee for the successful placement of this role is ${
                        loggedRecruiter.referrer=== true ?
                          this.state.jobForRetailTerms.salaryFrom
                              ? `$${
                                  (parseInt(this.state.jobForRetailTerms.referValue) / 100) *
                                  parseInt(this.state.jobForRetailTerms.salaryFrom)
                              }`
                              : ""
                      :
                        this.state.jobForRetailTerms.salaryFrom
                            ? `$${
                                (parseInt(this.state.jobForRetailTerms.feeValue) / 100) *
                                parseInt(this.state.jobForRetailTerms.salaryFrom)
                            }`
                            : ""
                  }`
                    : `Your recruitment fee for the successful placement of this role is ${
                          loggedRecruiter.referrer=== true ?
                            this.state.jobForRetailTerms.referValue
                            ? `$${this.state.jobForRetailTerms.referValue}`
                              : ""
                          :
                            this.state.jobForRetailTerms.feeValue
                                ? `$${this.state.jobForRetailTerms.feeValue}`
                                 : ""
                    }`
                }

              </Modal.Body>
            )}

            <Modal.Footer>
              <Button
                className="marign-right-10"
                variant="danger"
                onClick={this.hideRetailTermsEdit}
              >
                Close
              </Button>
              {scope === "admin" ? (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal centered show={this.state.showTerms} onHide={this._onTermsHide}>
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="terms-body">
            <div
              dangerouslySetInnerHTML={{ __html: terms.recruiterAcceptJobCast }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onTermsHide}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this._agreeToTerms}>
              I Agree
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          centered
          show={this.state.showJobDetails}
          onHide={this._onJobDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`Job details - 
              ${jobDetails ? jobDetails[0].header : ""}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(jobDetails || []).map((x, i) => {
              if (i === 0) return null;
              const arr = [];
              arr.push(<p key={`header-${i}`} style={{ fontWeight: 800, fontSize: '2em' }}>{x.header}</p>);
              if (Array.isArray(x.body)) {
                let j = 0;
                for (const {header, content} of x.body) {
                  if (header) {
                    arr.push(<strong key={`sub-header-${i}-${j}`}>{header}</strong>);
                  }
                  arr.push(<p key={`sub-content-${i}-${j}`}>{content || ' '}</p>);
                  j++;
                }
              } else if (typeof x.body === 'string') {
                arr.push(
                  <div
                    key={x.id}
                    id={x.id}
                    dangerouslySetInnerHTML={{
                      __html: x.body,
                    }}
                  />
                );
              }
              if (x.highlight) return <div style={{ backgroundColor: x.highlight }}>{arr}</div>;
              return arr;
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="primary"
              onClick={this._onDownloadPDF}
            >
              PDF
            </Button>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onJobDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={this.state.showCandidateDetails}
          onHide={this._onCandidateDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.showCandidateDetails
                ? `Candidate - ${selectedCandidate.firstName} ${selectedCandidate.lastName}`
                : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedCandidate ? (
              <>
                <h5 className="h5-job-details">Candidate Details</h5>
                {this.renderJobDetailsRow(
                  "First Name",
                  selectedCandidate.firstName
                )}
                {this.renderJobDetailsRow(
                  "Last Name",
                  selectedCandidate.lastName
                )}
                {this.renderJobDetailsRow(
                  "Current Employer",
                  selectedCandidate.currentEmployer
                )}
                {this.renderJobDetailsRow("Phone", selectedCandidate.phone)}
                {this.renderJobDetailsRow(
                  "Country",
                  returnTextForArrayValue(countries, selectedCandidate.country)
                )}
                {this.renderJobDetailsRow(
                  "Province/State",
                  returnTextForArrayValue(states, selectedCandidate.province)
                )}
                {this.renderJobDetailsRow(
                  "City",
                  selectedCandidate.city
                )}
                {this.renderJobDetailsRow(
                  "Email Address",
                  selectedCandidate.email
                )}
                {jobOfSelectedCandidate.questions ? (
                  <>
                    <hr />
                    <h5 className="h5-job-details">Pre-Screening Answers</h5>
                    {jobOfSelectedCandidate.questions
                      .split("\n")
                      .map((x, i) => {
                        return this.renderJobDetailsRow(
                          x,
                          selectedCandidate.response.split("\n")[i]
                        );
                      })}
                  </>
                ) : null}
                <hr />
                <h5 className="h5-job-details">Experience</h5>
                {this.renderJobDetailsRow(
                  "Highest College Degree",
                  selectedCandidate.highestCollegeDegree
                )}
                {this.renderJobDetailsRow(
                  "Most Recent Job Title",
                  selectedCandidate.mostRecentJobTitle
                )}
                {this.renderJobDetailsRow(
                  "Minimum Salary",
                  selectedCandidate.minSalary
                )}
                {this.renderJobDetailsRow(
                  "Maximum Salary",
                  selectedCandidate.maxSalary
                )}
                <hr />
                <h5 className="h5-job-details">Supporting Document</h5>
                {this.renderJobDetailsRow(
                  "Note From Candidate",
                  selectedCandidate.candidateNote
                )}
                {this.renderJobDetailsRow(
                  "Note From Recruiter",
                  selectedCandidate.recruiterNote
                )}
                {this.renderJobDetailsRow(
                  "LinkedIn Profile",
                  selectedCandidate.linkedInProfile
                )}
              </>
            ) : (
              "No details"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onCandidateDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  _changeFavoriteStatus = async({ jobId, favorite }) => {
    try {
      await this.props.changeFavoriteJobStatus({ jobId, favorite: !favorite });
    } catch (e) {
      console.error(e);
    }
  };

  _onDownloadPDF = async() => {
    const { jobDetails } = this.state;
    try {
      await downloadGeneratedPdf(jobDetails);
    } catch (e) {
      console.error(e);
    }
  };

  _onFavoriteFilterClick() {
    this.setState(
      { onlyFavorites: !this.state.onlyFavorites },
      () => this.findAllJobs()
    );
  }
}

const jobToDetails = (job) => [
  {
    header: job.jobTitle,
  },
  {
    header: 'Job Details',
    body: [
      {
        header: "Job Function",
        content: returnTextForArrayValue(jobFunctions, job.jobFunction),
      },
      {
        header: "Industry",
        content: returnTextForArrayValue(industries, job.industry),
      },
      {
        header: "Company Name",
        content: job.companyName,
      },
      {
        header: "Company Description",
        content: job.companyDescription,
      },
      {
        header: "Reporting To",
        content: job.reportingTo,
      },
      {
        header: "Team Size To Manage",
        content: job.teamSizeToManage,
      },
    ]
  },
  {
    header: 'Job Location',
    body: [
      {
        header: "Country",
        content: returnTextForArrayValue(countries, job.country),
      },
      {
        header: "Province/State",
        content: returnTextForArrayValue(states, job.state),
      },
      {
        header: "City",
        content: job.city,
      },
      {
        header: "Location Type",
        content: job.locationType,
      },
      {
        header: "Other Location",
        content: job.otherLocation,
      },
      {
        header: "Candidate Relocation",
        content: job.candidateRelocation,
      },
    ]
  },
  {
    header: 'Must Have Skills',
    highlight: '#ffff00',
    body: [
      {
        content: job.mustHave,
      },
      {
        header: "Skills Considered Bonus",
        content: job.skillsConsideredBonus,
      },
    ],
  },
  {
    header: 'Job Description',
    body: job.jobDescription,
    id: 'recruiter-home-page-job-description'
  },
  {
    header: 'Compensation',
    body: [
      {
        header: "Job Type",
        content: returnTextForArrayValue(jobTypes, job.jobType),
      },
      {
        header: "Salary From",
        content: job.salaryFrom,
      },
      {
        header: "Salary To",
        content: job.salaryTo,
      },
      {
        header: "Commission Payment",
        content: job.commissionPayment,
      },
      {
        header: "Bonus Payment",
        content: job.bonusPayment,
      },
    ]
  },
  {
    header: 'Mandatory Details',
    body: [
      {
        header: "Number Of Positions",
        content: job.numberOfPositions,
      },
    ]
  },
  {
    header: 'Culture, Perks and Benefits',
    body: job.culturePerksAndBenefits,
    id: 'recruiter-home-page-CPB-description'
  },
];

export default connect(
  (state) => ({
    jobs: state.jobs.allJobs,
    userActiveJobCastsIds: state.jobs.userActiveJobCastsIds,
    findingCandidatesForJob: state.jobs.findingCandidatesForJob,
    retailTerms: state.retailTerms.retailTerms,
    itemsCount: state.jobs.itemsCount,
    loggedRecruiter: state.recruiters.loggedRecruiter,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findAllJobs: findAllJobs,
        acceptJobCastTerms: acceptJobCastTerms,
        removeJobCast: removeJobCast,
        activeJobCast: activeJobCast,
        getJobCasts: getJobCasts,
        findCandidatesForJobByStatus: findCandidatesForJobByStatus,
        findRetailTerms: findRetailTerms,
        changeRetailTermForJob: changeRetailTermForJob,
        findLoggedRecruiter: findLoggedRecruiter,
        changeFavoriteJobStatus
      },
      dispatch
    )
)(RecruiterHomePage);
